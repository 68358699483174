<template>
  <v-container>
    <v-card class="elevation-2 mx-auto" :loading="loading" max-width="600">
      <v-card-title>Edit Attendee</v-card-title>
      <v-divider></v-divider>
      <v-card-text class="mt-5">
        <form>
          <v-row>
            <v-col cols="12" md="6" sm="12" class="py-0">
              <v-text-field label="First Name" v-model="formdata.first_name"
              outlined
              dense
              :error-messages="form_error.first_name">
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="12" class="py-0">
              <v-text-field label="Last Name" v-model="formdata.last_name"
              outlined
              dense
              :error-messages="form_error.last_name"
            ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="12" class="py-0">
              <v-text-field label="Email" v-model="formdata.email"
              outlined
              dense
              :error-messages="form_error.email"
              disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="12" class="py-0">
              <v-text-field label="Company Name" v-model="formdata.company_name"
              outlined
              dense
              :error-messages="form_error.company_name"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="12" class="py-0">
              <v-text-field label="Phone No." v-model="formdata.phone_number"
              outlined
              dense
              :error-messages="form_error.phone_number"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="12" class="py-0">
              <v-text-field label="Designation" v-model="formdata.designation"
              outlined
              dense
                :error-messages="form_error.designation"
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="12" class="py-0">
              <v-select
                v-model="formdata.sponsor_type"
                :error-messages="form_error.sponsor_type"
                :items="sponsor_type"
                item-text="name"
                item-value="sponsor_id"
                label="Member Type"
                outlined
                dense
              >
                <template slot="append-outer">
                  <v-tooltip
                    bottom
                    v-if="formdata.sponsor_type != originalDetails.sponsor_type && true"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        class="mt-n1"
                        v-bind="attrs"
                        v-on="on"
                        @click="resetSponserType()"
                      >
                        <v-icon>mdi-arrow-u-left-top</v-icon>
                      </v-btn>
                    </template>
                    <span>Reset</span>
                  </v-tooltip>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" md="6" sm="12" class="py-0">
              <v-text-field label="LinkedIn URL" v-model="formdata.linkedin_url"
              outlined
              dense
                :error-messages="form_error.linkedin_url"
                ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" sm="12" class="py-0">
              <v-select
              outlined
              dense
                :items="organization_type"
                item-text="name"
                item-value="category_id"
                label="Organization Type (Select all that apply)"
                :error-messages="form_error.organization_type"
                v-model="formdata.organization_type"
                multiple
              >
              </v-select>
            </v-col>
            <v-col cols="12" md="12" sm="12" class="py-0">
              <v-select
                label="Langauge Known"
                v-model="formdata.langauge_knows"
                multiple
                :items="langauge_knows"
                outlined
                dense
              >
              </v-select>
            </v-col>
            <v-col cols="12" md="12" sm="12" class="py-0" v-if="display_pic">
              <v-img :src="display_pic" width="30%"></v-img>
            </v-col>
            <v-col cols="12" md="12" sm="12" class="py-0" :class="(display_pic)?'mt-2':''">
              <v-file-input
                v-model="formdata.profile_pic"
                :error-messages="form_error.profile_pic"
                accept="image/*"
                placeholder="Select profile image"
                prepend-icon="mdi-file"
                outlined
                dense
              ></v-file-input>
            </v-col>
            <v-col cols="12" md="12" sm="12" class="py-0">
              <v-btn
                class="mr-4 mb-3"
                color="secondary"
                @click="updateAttendee"
                :loading="loading"
                :disabled="loading"
              >Update</v-btn>
            </v-col>
          </v-row>
        </form>
      </v-card-text>
    </v-card>
    <v-btn small color="secondary" fixed fab bottom left to="/attendees">
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>
  </v-container>
</template>
<script>
//import axios from "@/helper/axios";
import { mapMutations } from "vuex";
export default {
  name: "AddAttendee",
  data() {
    return {
      formdata : {
        organization_type: []
      },
      originalDetails: {},
      form_error: {},
      sponsor_type:[],
      organization_type: [],
      loading: false,
      display_pic: '',
      langauge_knows: [
        {
          value : 'ENG',
          text : 'English'
        },
        {
          value : 'ESP',
          text : 'Spanish'
        },
        {
          value : 'VIET',
          text : 'Vietnamese'
        }
      ],
    };
  },
  created: function () {
    let _self = this;
    this.$axios.get("/organization/list")
    .then(function (response) {
      _self.$axios.get(`admin/attendee/details?attendee_id=` + _self.$route.params.id)
      .then(function (response2) {
          _self.organization_type = response.data.data
          let profileData = response2.data.data
          _self.originalDetails = profileData;
          _self.formdata.first_name = profileData.first_name
          _self.formdata.last_name = profileData.last_name
          _self.formdata.email = profileData.email
          _self.display_pic = profileData.profile_pic
          _self.formdata.company_name = profileData.company_name
          _self.formdata.online_status = profileData.online_status
          _self.formdata.langauge_knows = profileData.langauge_knows
          if(profileData.sponsor_type != null) {
            _self.formdata.sponsor_type = profileData.sponsor_type
          }
          if(profileData.designation != null) {
            _self.formdata.designation = profileData.designation
          }
          if(profileData.phone_number != null) {
            _self.formdata.phone_number = profileData.phone_number
          }
          if(profileData.linkedin_url != null) {
            _self.formdata.linkedin_url = profileData.linkedin_url
          }

          for (let key in profileData.categories) {
            _self.formdata.organization_type.push(profileData.categories[key].category_id)
          }
      })
      .catch(function (e) {
        console.log(e);
      });
    })
    .catch(function (e) {
      console.log(e);
    });
  },
  methods: {
    ...mapMutations("utils", ["setAlert"]),
    updateAttendee() {
      let _self = this;
      this.loading = "secondary";
      let serverData = JSON.parse(JSON.stringify(this._data.formdata));
      let form_data = new FormData();
      for (let key in serverData) {
        if(key != 'organization_type') {
          form_data.append(key, serverData[key]);
        } else {
          for (let key2 in serverData[key]) {
            form_data.append(`organization_type[${key2}]`, serverData[key][key2]);
          }
        }
      }
      if (this.formdata.profile_pic) {
        form_data.append("profile_pic", this.formdata.profile_pic);
      }
      form_data.append("attendee_id", _self.$route.params.id);
      this.$axios.post(`admin/attendee/edit`, form_data)
        .then((response) => {
          _self.form_error = {};
          let response_data = response.data;
          if (response_data.status) {
            // updating data into the original details object
            this.originalDetails = {
              ...this.originalDetails,
              ...this.formdata
            }
            _self.setAlert({
              show: true,
              variant: "success",
              message: response.data.message,
              dismissCountDown: 5000,
            });
            _self.$router.push("/attendees");
          } else {
            let errors = response_data.error;
            for (let key in errors) {
              _self.form_error[errors[key].field] = errors[key].message;
            }
          }
          _self.loading = false;
        })
        .catch((e) => {
          console.log(e);
          _self.loading = false;
        });
    },
    resetSponserType() {
      if (this.originalDetails && this.originalDetails.sponsor_type) {
        this.formdata.sponsor_type = this.originalDetails.sponsor_type;
        this.form_error = {
          ...this.form_error,
          sponsor_type: null
        };
      }
    },
    getMemberList(){
      let _self = this;
      this.$axios.get(`admin/sponser_type/list?type=ATTENDEE`)
        .then((response) => {
          let response_data = response.data;
          if (response_data.status) {
            _self.sponsor_type = response_data.data;
          }
          _self.loading = false;
        })
        .catch((e) => {
          console.log(e);
          _self.loading = false;
        });
    }
  },
  mounted: function () {
    this.getMemberList();
  },
};
</script>